<template>
  <div>
    <h1 class="ui-title-page">RealHub Testimonials</h1>
    <div class="triagl triagl-btm"></div>
    <section class="section-area section_slider-reviews bg-gray section-testimoials" id="testimonials">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <router-link v-if="$auth.hasToken()" :to="{ name: 'testimonials.create' }" class="btn btn-primary float-right">Leave A Review</router-link>
            <h2 class="ui-title-block">WHAT CLIENTS SAY</h2>
            <div class="ui-subtitle-block">THE TESTIMONIALS</div>
            <div class="border-color border-color_center"></div>
          </div>
        </div>
      </div>
      <div class="container mt-5" v-if="! loading">
        <div class="row">
          <div class="col-md-6" v-for="(testimonial, i) in testimonials" :key="`testimonial-${i}`">
            <div class="about-autor clearfix bg-white">
              <div class="about-autor__foto">
                <img
                  :src="testimonial.avatar"
                  height="106"
                  width="106"
                  alt="Avatar"
                  class="img-responsive"
                />
              </div>
              <div class="about-autor__inner">
                <span class="about-autor__name">{{ testimonial.name }}</span
                >
                <rating :rating="testimonial.rating" />
                <div class="border-color"></div>
                <div class="about-autor__text ui-text">
                  "{{ testimonial.content }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <pagination v-model="pagination.page" :records="pagination.records" @paginate="fetch" :perPage="10" />
          </div>
        </div>
      </div>
      <loading v-if="loading" />
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      testimonials: [],
      pagination: { page: 1, records: 1 }
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/content/testimonials?page=${this.pagination.page}`).then(response => {
        this.testimonials = response.data.content.testimonials
        this.pagination = response.data.content.pagination
        this.loading = false
      })
    }
  }
}
</script>